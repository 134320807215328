$(document).ready(function(){
	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;

	$(window).scroll(function(event) {
	  didScroll = true;
	});

	setInterval(function() {
	  if (didScroll) {
		hasScrolled();
		didScroll = false;
	  }
	}, 100);

	function hasScrolled() {
	  var st = $(this).scrollTop();
    var navbarHeight = $('.talent-portal-header .header').outerHeight();

	  // Make sure they scroll more than delta
	  if (Math.abs(lastScrollTop - st) <= delta)
		return;

	  // If they scrolled down and are past the navbar, add class .nav-up.
	  // This is necessary so you never see what is "behind" the navbar.
	  if (st > lastScrollTop && st > navbarHeight) {
		// Scroll Down

		$('.talent-portal-header .header').removeClass('nav-down').addClass('nav-up');
	  } else {
		// Scroll Up
		if (st + $(window).height() < $(document).height()) {
		  $('.talent-portal-header .header').removeClass('nav-up').addClass('nav-down');
		}
	  }

	  lastScrollTop = st;
	}

	// Nav position active scrollspy
	$(window).bind('scroll', function() {
    var currentTop = $(window).scrollTop() + 400;
    if ($('.talent-portal .vision')) {
      currentTop = $(window).scrollTop() + 200;
    }
		var elems = $('.talent-portal .scroll-watch');
		elems.each(function(index){
		  var elemTop 	= $(this).offset().top;
		  var elemBottom 	= elemTop + $(this).height();
		  if(currentTop >= (elemTop -  parseInt($(this).css('margin-top'))) && currentTop <= elemBottom){
			var id 		= $(this).attr('id');
			var navElem = $('.talent-portal .content-sidebar-group ul li a[fragment="' + id+ '"]');
      navElem.parent('li').addClass('active-nav-link').siblings().removeClass( 'active-nav-link' )
			navElem.closest('.talent-portal .content-sidebar-group').addClass('active-group').siblings().removeClass( 'active-group' );
      var altNavElem = $('.talent-portal .vision .nav-bar a[fragment="' + id+ '"]');
      altNavElem.addClass('active-nav-link').siblings().removeClass( 'active-nav-link' );
		  }
		})
	});

	// always listen for .track-visibility items coming into view
	$(window).scroll(function() {
		$('.talent-portal .track-visibility').each(function() {
			var top_of_element = $(this).offset().top;
			var bottom_of_element = $(this).offset().top + $(this).outerHeight();
			var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
			var top_of_screen = $(window).scrollTop();

			if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) && !$(this).hasClass('is-visible')) {
				$(this).addClass('is-visible');
			}
		});
	});

	// listen for .track-visibility items coming into view on homepage inside scroll-snap container
	$('.talent-portal .scroll-container').scroll(function() {
		$('.talent-portal .track-visibility').each(function() {
			var top_of_element = $(this).offset().top;
			var bottom_of_element = $(this).offset().top + $(this).outerHeight();
			var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
			var top_of_screen = $(window).scrollTop();

			if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) && !$(this).hasClass('is-visible')) {
				$(this).addClass('is-visible');
			}
		});
	});

	// Header scroll
	$(document).scroll(function() {
		var y = $(this).scrollTop();
		if (y > 100) {
			$('.talent-portal-header .header').addClass('scrolled');
		} else {
			$('.talent-portal-header .header').removeClass('scrolled');
		}
		if (y > 800) {
			$('.talent-portal .content-tabs--fixed').addClass('scrolled');
		} else {
			$('.talent-portal .content-tabs--fixed').removeClass('scrolled');
		}
	});

	function onScroll(event){
		var scrollPos = $(document).scrollTop();
		$('.talent-portal .content-area-card-nav ul li a').each(function () {
			var currLink = $(this);
			var refElement = $(currLink.attr("href"));
			if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
				$('.talent-portal .content-area-card-nav ul li a').removeClass("active");
				currLink.addClass("active");
			}
			else{
				currLink.removeClass("active");
			}
		});
	}

	$(".talent-portal .talent-journey-floating-nav").stop().click(function(){
		$(this).toggleClass("close");
		$('.content-sidebar').toggleClass("in");
	});

});

/* Update nav based on scroll position of cards and IDs

window.addEventListener('DOMContentLoaded', () => {

let options = {
	root: null,
	rootMargin: "0px",
	threshold: 0.7
  };

	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			const id = entry.target.getAttribute('id');
			if (entry.intersectionRatio > 0) {
				document.querySelector(`.content-area-card-nav ul li a[href="#${id}"]`).parentElement.classList.add('active');
			} else {
				document.querySelector(`.content-area-card-nav ul li a[href="#${id}"]`).parentElement.classList.remove('active');
			}
		});
	});

	// Track all sections that have an `id` applied
	document.querySelectorAll('.content-area-card[id]').forEach((section) => {
		observer.observe(section);
	});

});

*/
